import { Button, Col, Form, Input, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { actionAddBags, actionUpdateBag } from '../../../store/services/lotService';

const EditGradeASTMc = ({ id, setModalVisible}) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [customValidationError, setCustomValidationError] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();


  const grade = useSelector(state => state.lot)
  const { user } = useSelector(state => state.auth)

  const { singleBag, singleBagLoader } = grade;

  useEffect(() => {
    if (singleBag) {
      form.setFieldsValue({
        ps_16: singleBag.ps_16 ? singleBag.ps_16 : "",
        // ps_20: singleBag.ps_20 ? singleBag.ps_20 : "",
        ps_30: singleBag.ps_30 ? singleBag.ps_30 : "",
        ps_40: singleBag.ps_40 ? singleBag.ps_40 : "",
        ps_50: singleBag.ps_50 ? singleBag.ps_50 : "",
        ps_100: singleBag.ps_100 ? singleBag.ps_100 : "",
      })
    }
  }, [singleBag,form])

  const onFinish = (values) => {
    const req = {
      ...values,
      type: "Astm_c778_grade_sand_VI",
      ps_16: values.ps_16 ? parseFloat(values.ps_16).toFixed(2) : "",
      // ps_20: values.ps_20 ? parseFloat(values.ps_20).toFixed(2) : "",
      ps_30: values.ps_30 ? parseFloat(values.ps_30).toFixed(2) : "",
      ps_40: values.ps_40 ? parseFloat(values.ps_40).toFixed(2) : "",
      ps_50: values.ps_50 ? parseFloat(values.ps_50).toFixed(2) : "",
      ps_100: values.ps_100 ? parseFloat(values.ps_100).toFixed(2) : "",
      // user_id: user?.id,
      // screen: selectedValue,
    };
    dispatch(actionUpdateBag({ id: id, val: req, setModalVisible, form }))
  };

  const onFinishFailed = (err) => {
    console.log({ err });
  }
  return (
    <>
      <div className='rounded-[15px] px-4 py-1 bg-white  my-2 '>
        <Form
          initialValues={{
            ps_16: 100
            // ps_20: 100
          }}
          className="lot-form"
          form={form}
          layout="vertical"
          size="large"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <div className='text-[16px] text-[#000] mt-3'>
            ASTM C-778-21 ( Graded Sand )
          </div>

          <Row gutter={[16, 0]} className="graed  mt-3">
            <Col span={12}>
              <Form.Item
                label="Particle Size in #16"
                name="ps_16"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in #16!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !(value >= 90.00 && value <= 100.00)) {
                        return Promise.reject('Please enter a valid value between 90.00 and 100.00!');
                      }

                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input className='' placeholder='Enter Particle Size in #16' />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label="Particle Size in #20"
                name="ps_20"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in #20!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !(value >= 90.00 && value <= 100.00)) {
                        return Promise.reject('Please enter a valid value between 90.00 and 100.00!');
                      }

                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input className='' placeholder='Enter Particle Size in #20' />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Particle Size in #30</p><p className='text-[12px] text-[#717171] text-right'>( 96.00 - 100.00 )</p></div>}
                name="ps_30"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in #30!',
                  },

                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && !(value >= 96.00 && value <= 100.00)) {
                        return Promise.reject('Please enter a valid value between 96.00 and 100.00!');
                      }

                      return Promise.resolve();
                    },
                  })
                ]}

              >
                <Input placeholder='Enter Particle Size in #30' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Particle Size in #40</p><p className='text-[12px] text-[#717171] text-right'>( 60.00 - 75.00 )</p></div>}
                name="ps_40"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in #40!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {

                      if (value && !(value >= 60.00 && value <= 75.00)) {
                        return Promise.reject('Please enter a valid value between 60.00 and 75.00!');
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input placeholder='Enter Particle Size in #40' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Particle Size in #50</p><p className='text-[12px] text-[#717171] text-right'>( 16.00 - 30.00 )</p></div>}
                name="ps_50"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in #50!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {

                      if (value && !(value >= 16.00 && value <= 30.00)) {
                        return Promise.reject('Please enter a valid value between 16.00 and 30.00!');
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input placeholder='Enter Particle Size in #50' />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Particle Size in #100</p><p className='text-[12px] text-[#717171] text-right'>( 0.00 - 4.00 )</p></div>}
                name="ps_100"
                normalize={(value) =>
                  value.replace(/[^0-9.]/g, "").trim()
                }
                rules={[
                  {
                    required: true,
                    message: 'Please Enter Particle Size in #100!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {

                      if (value && !(value >= 0.00 && value <= 4.00)) {
                        return Promise.reject('Please enter a valid value between 0.00 and 4.00!');
                      }
                      return Promise.resolve();
                    },
                  })
                ]}
              >
                <Input placeholder='Enter Particle Size in #100' />
              </Form.Item>
            </Col>
          </Row>

          <div className='footer z-0  flex items-center gap-x-2 bottom-0 w-full   bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3'>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button htmlType="submit" className='outline-btn 2xl:text-[16px] xl:text-[14px] text-[12px] ml-2'>Update  Bag</Button>
            </Form.Item>
          </div>
        </Form></div>
    </>
  )
}

export default EditGradeASTMc
import { LoadingOutlined, EditOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Input,
  List,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Segmented,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import greymoon from "../../assets/img/all/greymoon.svg";
import greysun from "../../assets/img/all/greysun.svg";
import lotAction from "../../assets/img/all/lotAction.svg";
import moon from "../../assets/img/all/moon.svg";
import search from "../../assets/img/all/search.svg";
import sun from "../../assets/img/all/sun.svg";
import resfreshIcon from "../../assets/img/dashboard/Refresh.svg";
import iconGradeI from "../../assets/img/grade/iconGradeI.svg";
import iconGradeII from "../../assets/img/grade/iconGradeII.svg";
import iconGradeIII from "../../assets/img/grade/iconGradeIII.svg";
import iconGradeIV from "../../assets/img/grade/iconGradeIV.svg";
import iconGradeIX from "../../assets/img/grade/iconGradeIX.svg";
import iconGradeV from "../../assets/img/grade/iconGradeV.svg";
import iconGradeVI from "../../assets/img/grade/iconGradeVI.svg";
import iconGradeVII from "../../assets/img/grade/iconGradeVII.svg";
import iconGradeVIII from "../../assets/img/grade/iconGradeVIII.svg";
import iconGradeX from "../../assets/img/grade/iconGradeX.svg";
import print from "../../assets/img/all/print.svg";
import {
  actionChangeBatchStatus,
  actionDownloadBags,
  actionDownloadSingleBag,
  actionGetBagDetail,
  actionGetBatchDetail,
  actionGetBatchs,
  getMachine,
} from "../../store/services/lotService";
import SideCardHeader from "../common/SideCardHeader";
import EditGradeASTM2 from "./grade/EditGradeASTM2";
import EditGradeASTMc from "./grade/EditGradeASTMc";
import EditGradeASTMd from "./grade/EditGradeASTMd";
import EditGradeBS13 from "./grade/EditGradeBS13";
import EditGradeBSEN from "./grade/EditGradeBSEN";
import EditGradeDS from "./grade/EditGradeDS";
import EditGradeI from "./grade/EditGradeI";
import EditGradeII from "./grade/EditGradeII";
import EditGradeIII from "./grade/EditGradeIII";
import EditGradeFiltermediaSand from "./grade/EditGradeFiltermediaSand";

const Lot = () => {
  const dispatch = useDispatch();
  const offsetRef = useRef(0);
  const [data, setData] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState(0);
  const [time, setTime] = useState(null);
  const [grade, setGrade] = useState(undefined);
  const [staticId, setStaticId] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [keyword, setKeyword] = useState("");
  const [date, setDate] = useState(null);
  const [selectedItemType, setSelectedItemType] = useState("");
  const [lotId, setLotId] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  // onClick={() => setTooltipVisible(false)} // Hide tooltip when the button is clicked
  // onMouseEnter={() => setTooltipVisible(true)} // Show tooltip on mouse enter
  // onMouseLeave={() => setTooltipVisible(false)} // Hide tooltip on mouse leave

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const batchData = useSelector((state) => state.lot);
  const {
    batchs,
    SingleBatch,
    changeStatusLoader,
    downloadSingleBagLoader,
    singleBagLoader,
    singleBatchLoader,
    batchsCount,
    downloadQrLoader,
    machines,
    getMachinesLoader,
    getBatchsLoader,
    singleBag,
  } = batchData;

  useEffect(() => {
    dispatch(
      actionGetBatchs({
        offset: 0,
        limit: 10,
        keyword: keyword ? keyword : "",
        date: date ? dayjs(date).format("YYYY-MM-DD") : "",
      })
    );
    dispatch(getMachine({ limit: 10, offset: 0, getall: "YES" }));
  }, [dispatch]);

  const handleWithTime = (e) => {
    setTime(e);
    dispatch(
      actionGetBatchs({
        offset: 0,
        limit: 10,
        keyword: keyword ? keyword : "",
        date: date ? dayjs(date).format("YYYY-MM-DD") : "",
        manufacture_time: e ? e : "",
      })
    );
  };

  useMemo(() => {
    const newData =
      batchs &&
      batchs.length &&
      batchs?.map((batch, i) => ({
        key: i,
        num: (pagination.current - 1) * pagination.pageSize + i + 1,
        id: batch.id,
        created_by: batch.created_by,
        date: batch.created_at
          ? moment(batch.created_at).format("DD MMM YYYY, hh:mm A")
          : "-",
        time_of_manufacture:
          batch.manufacture_time === "D" ? (
            <div className="flex space-x-2">
              <img src={sun} alt="Day" />
              <span>Day</span>
            </div>
          ) : (
            <div className="flex space-x-2">
              <img src={moon} alt="Night" />
              <span>Night</span>
            </div>
          ),
        lot_number: batch.batch_count && `B ${batch.batch_count}`,
        bag_count: batch.qty,
        status: batch.status,
      }));
    setData(newData);
    batchs && batchs.length > 0 && setStaticId(batchs[0].id);
  }, [batchData]);

  useEffect(() => {
    if (SingleBatch && (grade || selectedValue)) {
      dispatch(
        actionGetBatchDetail({
          id: SingleBatch?.id,
          offset: 0,
          limit: 50,
          type: grade || "",
          screen: selectedValue || "",
        })
      );
    }
  }, [grade, selectedValue]);

  const getBagNameFromType = (type) => {
    let name;
    let imageIcon;
    switch (type) {
      case "Standard_sand_grade_I":
        name = "Grade-I";
        imageIcon = iconGradeI;
        break;
      case "Standard_sand_grade_II":
        name = "Grade-II";
        imageIcon = iconGradeII;
        break;
      case "Standard_sand_grade_III":
        name = "Grade-III";
        imageIcon = iconGradeIII;
        break;
      case "Standard_Density_sand_IV":
        name = "Density Sand";
        imageIcon = iconGradeVII;
        break;
      case "Bsen196_iso679_V":
        name = "BSEN 196";
        imageIcon = iconGradeIV;
        break;
      case "Astm_c778_grade_sand_VI":
        name = "ASTM C-778";
        imageIcon = iconGradeV;
        break;
      case "Astm_20_30_sand_VII":
        name = "ASTM 20/30";
        imageIcon = iconGradeVI;
        break;
      case "Bs_1377_density_sand_VIII":
        name = "BS 1377";
        imageIcon = iconGradeVIII;
        break;
      case "Astm_d1556m_15_density_sand_IX":
        name = "ASTM D1556";
        imageIcon = iconGradeIX;
        break;
        case "Is_17955_X":
        name = "Filtermedia Sand";
        imageIcon = iconGradeX;
        break;
    }
    return { name, imageIcon };
  };

  useEffect(() => {
    staticId &&
      dispatch(
        actionGetBatchDetail({
          id: staticId,
          offset: 0,
          limit: 50,
          type: grade || "",
          screen: selectedValue || "",
        })
      );
    setSelectedRowKey(0);
  }, [staticId]);

  const handleTableChange = (page, pageSize) => {
    setPagination({
      current: page,
      pageSize,
      total: batchsCount,
    });
    dispatch(
      actionGetBatchs({
        offset: page - 1,
        limit: pageSize,
        date: date ? dayjs(date).format("YYYY-MM-DD") : "",
        keyword: keyword || "",
        manufacture_time: time || "",
      })
    );
  };

  const downloadBag = async (id) => {
    dispatch(
      actionDownloadBags({
        id: id,
        type: grade,
        screen: selectedValue,
        limit: pagination.pageSize,
        offset: grade || selectedValue ? 0 : offsetRef.current,
        
      })
    );
    //  dispatch(actionGetBatchDetail({ id: id, limit: pagination.pageSize, offset: (grade || selectedValue) ? 0 : offsetRef.current, type: grade, screen: selectedValue }))
  };
  const downdSinglePdf = async (id, batchnum) => {
    dispatch(
      actionDownloadSingleBag({
        id: id,
        batch_number: batchnum,
        is_print: "YES",
      })
    );
  };

  const columns = [
    {
      title: "#",
      // width: 100,
      dataIndex: "num",
      key: "num",
    },
    {
      title: "Creation Date",
      // width: 200,
      ellipsis: true,
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Time Of Manufacture",
      dataIndex: "time_of_manufacture",
      key: "time_of_manufacture",
      // width: 150,
      ellipsis: true,
    },
    {
      title: "Batch Number",
      dataIndex: "lot_number",
      key: "lot_number",
      // width: 150,
      ellipsis: true,
    },
    {
      title: "Bag Qty",
      dataIndex: "bag_count",
      key: "bag_count",
      ellipsis: true,
      width: 100,
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      // width: 150,
      ellipsis: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      fixed: "right",
      render: (text) => (
        // <Dropdown
        //     menu={{
        //         items,
        //         // onClick,
        //     }}
        // >
        //     <a onClick={(e) => e.preventDefault()}>
        //         <Space>
        //             Hover me, Click menu item
        //             <DownOutlined />
        //         </Space>
        //     </a>
        // </Dropdown>

        <Button
          id="hover-white"
          className={`min-w-[120px] failed  flex items-center justify-center border-0 ${
            (text === "PENDING" || text === "Failed") && "text-white"
          }  capitalize rounded-[15px] flex items-center py-3 hover:text-white px-6 h ${
            text === "COMPLETED"
              ? "bg-green-200"
              : text === "PENDING"
              ? "bg-[#7084A3]"
              : text === "IN_PROCESS"
              ? "bg-[#E5DDEC]"
              : text === "Failed"
              ? "bg-red-400 text-color-white"
              : ""
          }`}
        >
          {text === "IN_PROCESS" ? "In Process" : text.toLocaleLowerCase()}
        </Button>
      ),
    },
  ];

  const handleGetWithScreen = (letter) => {
    setSelectedValue(letter);
  };

  const handleGetWithGrade = (val) => {
    setGrade(val);
  };

  const handleGetWithDate = (date, dateString) => {
    setDate(dateString ? dayjs(dateString) : null);
    console.log({ dateString, date });
    dispatch(
      actionGetBatchs({
        offset: 0,
        limit: 10,
        date: dateString ? dateString : "",
        keyword: keyword ? keyword : "",
        manufacture_time: time ? time : "",
      })
    );
  };

  const onLoadMore = () => {
    if (
      !singleBatchLoader &&
      SingleBatch &&
      SingleBatch.bags &&
      SingleBatch.bags?.bags?.length > 0 &&
      SingleBatch.bags?.bags?.length < SingleBatch?.bags?.total_bags
    ) {
      console.log("Conditions met. Fetching more data.");

      dispatch(
        actionGetBatchDetail({
          id: SingleBatch.id,
          limit: 50,
          offset: offsetRef.current + 1,
          type: grade,
          screen: selectedValue,
        })
      );
      offsetRef.current += 1;
    }
  };

  const LoadMore = () => {
    const showButton =
      SingleBatch &&
      SingleBatch.bags &&
      SingleBatch.bags?.bags &&
      SingleBatch.bags?.bags?.length >= 50 &&
      SingleBatch.bags?.bags?.length < SingleBatch?.bags?.total_bags;

    return showButton ? (
      <div className="text-center h-[60px]">
        {singleBatchLoader ? (
          <Spin indicator={<LoadingOutlined />} />
        ) : (
          <Button
            style={{
              borderRadius: 5,
              padding: "5px 15px",
              border: "1px solid #B2BCCF",
            }}
            onClick={onLoadMore}
            className="text-center cursor-pointer rounded-sm mx-auto outline-btn border-1-[#B2BCCF]"
          >
            Load More...
          </Button>
        )}
      </div>
    ) : null;
  };

  const handleModalOpen = (type, id, batchNum) => {
    setSelectedItemType(type);
    setLotId(id);
    setModalVisible(true);
    dispatch(actionGetBagDetail(batchNum));
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedItemType(null);
  };

  const handleResetSerchValue = () => {
    setTime(undefined);
    setDate(undefined);
    setKeyword("");
    dispatch(actionGetBatchs({ offset: 0, limit: 10 }));
  };

  const renderTypeForms = () => {
    // Implement logic to render different forms for each item type
    switch (selectedItemType) {
      case "Standard_sand_grade_I":
        return (
          <EditGradeI
            id={lotId}
            type={selectedItemType}
            setModalVisible={setModalVisible}
          />
        );
      case "Standard_sand_grade_II":
        return (
          <EditGradeII
            id={lotId}
            type={selectedItemType}
            setModalVisible={setModalVisible}
          />
        );
      case "Standard_sand_grade_III":
        return (
          <EditGradeIII
            id={lotId}
            type={selectedItemType}
            setModalVisible={setModalVisible}
          />
        );
      case "Standard_Density_sand_IV":
        return (
          <EditGradeDS
            id={lotId}
            type={selectedItemType}
            setModalVisible={setModalVisible}
          />
        );
      case "Bsen196_iso679_V":
        return (
          <EditGradeBSEN
            id={lotId}
            type={selectedItemType}
            setModalVisible={setModalVisible}
          />
        );
      case "Astm_c778_grade_sand_VI":
        return (
          <EditGradeASTMc
            id={lotId}
            type={selectedItemType}
            setModalVisible={setModalVisible}
          />
        );
      case "Astm_20_30_sand_VII":
        return (
          <EditGradeASTM2
            id={lotId}
            type={selectedItemType}
            setModalVisible={setModalVisible}
          />
        );
      case "Bs_1377_density_sand_VIII":
        return (
          <EditGradeBS13
            id={lotId}
            type={selectedItemType}
            setModalVisible={setModalVisible}
          />
        );
      case "Astm_d1556m_15_density_sand_IX":
        return (
          <EditGradeASTMd
            id={lotId}
            type={selectedItemType}
            setModalVisible={setModalVisible}
          />
        );
        case "Is_17955_X":
        return (
          <EditGradeFiltermediaSand
            id={lotId}
            type={selectedItemType}
            setModalVisible={setModalVisible}
          />
        );
      default:
        return null;
    }
  };

  const confirm = (id, updatedStatus) => {
    dispatch(actionChangeBatchStatus({ id, updatedStatus }));
  };

  return (
    <div
      className="flex justify-center h-full max-h-screen gatepass-container"
      id="lot-container"
    >
      {/* Content Section */}
      <div className="bg-[#F4F8FF] w-[70%] relative custom-scrollbar flex flex-col overflow-hidden">
        <Row
          justify={"center"}
          align={"middle"}
          className="border-b border-b-[#919da94d] items-center justify-between 2xl:h-[85px] xl:h-[70px] lg:h-[60px] sm:h-[55px]"
        >
          <Col xxl={9} xl={4} lg={4} xm={4}>
            <p className="font-GlegooBold text-primary 2xl:text-[36px] xl:text-[26px] lg:text-[18px] ml-5">
              Batch
            </p>
          </Col>

          <Col xxl={8} lg={10} sm={10} xl={10} align="end">
            <DatePicker
              value={date} // Controlled component
              placeholder="Select Date"
              className="w-1/2"
              onChange={handleGetWithDate}
              format={"YYYY-MM-DD"}
            />
          </Col>
          <Col xxl={5} lg={8} sm={8} xl={7} align="end" className="xl:py-2">
            <Input
              onChange={(val) => {
                setKeyword(val.target.value);
                dispatch(
                  actionGetBatchs({
                    limit: 10,
                    offset: 0,
                    keyword: val.target.value,
                    date: date ? dayjs(date).format("YYYY-MM-DD") : "",
                  })
                );
              }}
              className="custom-search "
              placeholder="Search..."
              prefix={
                <Segmented
                  value={time}
                  defaultValue={false}
                  onChange={(e) => handleWithTime(e)}
                  className="pl-3 bg-transparent "
                  id="Dark-Segment"
                  size="large"
                  options={[
                    {
                      value: "D",
                      icon: (
                        <div className="p-1">
                          <img src={sun} alt="sun" className="bluesun" />
                          <img src={greysun} alt="sun" className="greysun" />
                        </div>
                      ),
                    },
                    {
                      value: "N",
                      icon: (
                        <div className="p-1">
                          <img src={moon} alt="moon" className="bluemoon" />
                          <img src={greymoon} alt="moon" className="greymoon" />
                        </div>
                      ),
                    },
                  ]}
                />
              }
              suffix={<img src={search} alt="search" />}
            />
          </Col>
          <Col
            xxl={1}
            lg={1}
            sm={1}
            xl={1}
            align="middle"
            className="relative right-2"
          >
            <div
              onClick={handleResetSerchValue}
              className="flex justify-center items-center gap-x-2"
            >
              <img
                src={resfreshIcon}
                alt="recent"
                width={40}
                className="cursor-pointer"
              />
            </div>
          </Col>
        </Row>

        <div className="flex-1 overflow-hidden px-6 py-4 scrollbar-container">
          <Table
            loading={getBatchsLoader || changeStatusLoader}
            className="table-scrollbar"
            columns={columns}
            dataSource={data}
            rowClassName={(record) =>
              record.key === selectedRowKey ? "selected-row" : ""
            }
            onRow={(record) => ({
              onClick: () => {
                if (record.key !== selectedRowKey)
                  dispatch(
                    actionGetBatchDetail({
                      id: record.id,
                      offset: 0,
                      limit: 50,
                    })
                  );
                setSelectedValue("");
                setGrade(undefined);
                setSelectedRowKey(record.key);
              },
            })}
            pagination={false}
            scroll={{
              x: "max-content",
              y: window.innerWidth < 1500 ? "62vh" : "70vh",
            }}
          />
        </div>
        <div
          className="flex items-center justify-between 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px] border-t border-t-custom-gray border-opacity-[30%] px-5"
          align={"middle"}
        >
          <Link to="/add-batch">
            <Button className="filled-btn 2xl:text-[16px] xl:text-[14px] text-[12px]">
              Create New Batch
            </Button>
          </Link>
          <div className="flex justify-end" id="custom-pagination">
            <Pagination
              className="flex items-center"
              {...pagination}
              showSizeChanger
              showQuickJumper
              hideOnSinglePage
              pageSizeOptions={["10", "20", "30"]}
              total={batchsCount}
              onChange={(page, pageSize) => handleTableChange(page, pageSize)}
            />
          </div>
        </div>
      </div>
      {/* Content Section */}

      {/* Sidebar  Section Start Here */}
      <div className="bg-white  border-l   color-scroll  border-l-[#919da94d] w-[30%] flex flex-col flex-1 overflow-hidden">
        <SideCardHeader />
        {SingleBatch ? (
          <div className=" flex-1 flex flex-col  overflow-hidden py-1">
            <div className="px-4  ">
              <div className=" flex  justify-between items-center w-full border-b py-4 border-opacity-[30%] border-custom-gray">
                <div className="flex items-center justify-center py-2 px-3 h-[45px] w-[45px] bg-[#E9E9ED] rounded-[15px]">
                  {SingleBatch?.manufacture_time === "D" ? (
                    <div className="flex space-x-2">
                      <img src={sun} alt="Day" />
                    </div>
                  ) : (
                    <div className="flex space-x-2">
                      <img src={moon} alt="Night" />
                    </div>
                  )}
                </div>
                <div className="flex-col justify-stretch items-center ml-3 ">
                  <p className="text-primary 2xl:text-[20px] xl:text-[16px] text-[12px] font-gilroyBold lg:text-[14px] font-bold">
                    {"B" + SingleBatch?.batch_count} Batch{" "}
                    <span className="text-[12px] font-normal">
                      ({SingleBatch?.bags?.total_bags || 0} Bags)
                    </span>
                  </p>
                  <p className="text-[#919DA9] 2xl:text-[10px] xl:text-[10px] font-gilroyMedium lg:text-[10px] text-[10px]">
                    {SingleBatch?.created_at
                      ? moment(SingleBatch?.created_at).format(
                          "DD MMM YYYY, hh:mm A"
                        )
                      : "-"}
                    {/* <Tag className='  ml-3  ' color="red">red</Tag> */}
                    {SingleBatch.status === "COMPLETED" && (
                      <Popconfirm
                        okButtonProps={{
                          loading: changeStatusLoader,
                        }}
                        // placement="bottom"
                        title="Change Status"
                        description="Are you sure to Change Status as Failed?"
                        onConfirm={() => confirm(SingleBatch.id, "Failed")}
                        // onConfirm={() => console.log("cLIEKD")}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Tooltip
                          open={tooltipVisible}
                          color="red"
                          title="Click here to Change Status as Failed for this Batch"
                        >
                          <Tag
                            onClick={() => setTooltipVisible(false)} // Hide tooltip when the button is clicked
                            onMouseEnter={() => setTooltipVisible(true)} // Show tooltip on mouse enter
                            onMouseLeave={() => setTooltipVisible(false)} // Hide tooltip on mouse leave
                            className="cursor-pointer py-0.5 px-3 ml-2 capitalize text-xs"
                            color="error"
                          >
                            Mark as Failed
                          </Tag>
                        </Tooltip>
                      </Popconfirm>
                    )}
                  </p>
                </div>

                <div className="ml-auto flex items-center justify-center">
                  {downloadQrLoader[SingleBatch.id] ? (
                    <Typography.Text className="text-left px-5">
                      <LoadingOutlined />
                    </Typography.Text>
                  ) : (
                    <Typography.Text
                      className="flex text-primary hover:text-primary cursor-pointer"
                      disabled={
                        !SingleBatch?.bags?.total_bags ||
                        SingleBatch.status === "IN_PROCESS"
                      }
                      onClick={() =>
                        SingleBatch?.bags?.total_bags &&
                        SingleBatch.status !== "IN_PROCESS" &&
                        downloadBag(SingleBatch.id)
                      }
                    >
                      <img
                        src={lotAction}
                        alt="action"
                        className="action-icon"
                      />
                      <span>Print</span>
                    </Typography.Text>
                  )}
                </div>
              </div>
            </div>
            <div className="border-b border-b-[#919da94d]">
              <Row
                gutter={[0, 0]}
                className="py-3 px-4 mr-0 ml-0"
                align={"bottom"}
              >
                <Col xxl={24} xl={24}>
                  <div className="">
                    <p className="text-custom-gray text-[12px] mb-2">Screen</p>
                    {getMachinesLoader ? (
                      <Spin
                        className="my-2"
                        size="small"
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: 24,
                              color: "#00205E",
                            }}
                            spin
                          />
                        }
                      />
                    ) : (
                      <div className="mt-1 border-2 w-full flex border-[#B2BCCF] text-[14px] font-semibold overflow-x-auto ">
                        {machines &&
                          machines.length &&
                          machines.map((letter, index) => (
                            <div
                              key={letter.id}
                              className={`border-r cursor-pointer  ${
                                machines.length - 1 === index
                                  ? "border-r-0"
                                  : "border-r-1"
                              } flex-1 border-[#B2BCCF] bg-[#fff] p-1 flex items-center justify-center ${
                                selectedValue === letter.name
                                  ? "bg-primary text-white"
                                  : ""
                              }`}
                              onClick={() => {
                                handleGetWithScreen(letter.name);
                              }}
                            >
                              {letter.name}
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </Col>
                <Col xxl={20} xl={12} className="xl:mt-2 mb-2">
                  <div className="w-full">
                    <p className="text-custom-gray text-[12px] mb-2">
                      Certificate Type
                    </p>

                    <Select
                      className="lot-select text-custom-gray w-full"
                      showSearch
                      allowClear
                      value={grade}
                      onClear={() => {
                        setGrade(undefined);
                        if (!singleBatchLoader)
                          dispatch(
                            actionGetBatchDetail({
                              id: SingleBatch.id,
                              offset: 0,
                              limit: 50,
                              screen: selectedValue || "",
                            })
                          );
                      }}
                      placeholder="Select  Grade"
                      onSelect={(val) => {
                        handleGetWithGrade(val);
                      }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        { value: "Standard_sand_grade_I", label: "Grade-I" },
                        { value: "Standard_sand_grade_II", label: "Grade-II" },
                        {
                          value: "Standard_sand_grade_III",
                          label: "Grade-III",
                        },
                        {
                          value: "Standard_Density_sand_IV",
                          label: "Density Sand",
                        },
                        { value: "Bsen196_iso679_V", label: "BSEN 196" },
                        {
                          value: "Astm_c778_grade_sand_VI",
                          label: "ASTM C-778",
                        },
                        { value: "Astm_20_30_sand_VII", label: "ASTM 20/30" },
                        {
                          value: "Bs_1377_density_sand_VIII",
                          label: "BS 1377",
                        },
                        {
                          value: "Astm_d1556m_15_density_sand_IX",
                          label: "ASTM D1556",
                        },
                      ]}
                    />
                  </div>
                </Col>
                {/* <Col span={4}> */}
                <Col xxl={4} xl={6} align={"right"} className="mb-2">
                  <Button
                    onClick={() => {
                      setSelectedValue("");
                      setGrade(undefined);
                      dispatch(
                        actionGetBatchDetail({
                          id: SingleBatch.id,
                          limit: 50,
                          offset: 0,
                        })
                      );
                    }}
                    className="bg-primary text-white hover:text-white rounded-[15px]"
                    id="reset-btn"
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="px-2 overflow-auto flex-1  pt-3">
              <List
                grid={{
                  xl: 1,
                  xxl: 2,
                }}
                className="demo-loadmore-list"
                loading={singleBatchLoader}
                itemLayout="horizontal"
                loadMore={<LoadMore />}
                dataSource={SingleBatch?.bags?.bags}
                // pagination
                renderItem={(item) => {
                  return (
                    <List.Item>
                      <Card
                        hoverable
                        className="lot-side-section-card border-none mx-[8px] "
                      >
                        <div
                          className={`border relative border-1-[#B2BCCF] p-3 rounded-[8px] flex items-center space-x-2  ${
                            item?.print_count > 0 ? "bg-[#F2F4F7]" : "bg-[#fff]"
                          }`}
                        >
                          <img
                            src={getBagNameFromType(item?.type)?.imageIcon}
                            alt="iconGradeI"
                          />

                          <div className="space-y-1 w-full flex items-center justify-between">
                            <div className="flex flex-col justify-center gap-y-1">
                              <p
                                className={`text-black  font-gilroyMedium 2xl:text-[14px] xl:text-[13px] text-[10px] ${
                                  item?.print_count > 0 && "leading-4"
                                }`}
                              >
                                {getBagNameFromType(item?.type).name}
                              </p>
                              <p
                                className={`text-[#919DA9] font-gilroyBold 2xl:text-[10px] xl:text-[9px] text-[8px] ${
                                  item?.print_count > 0 && "leading-[10.38px]"
                                }`}
                              >
                                Machine: {item?.screen}
                              </p>
                              {item?.print_count > 0 && (
                                <p
                                  className={`text-[#919DA9] font-gilroySemiBold 2xl:text-[9px] xl:text-[7px] text-[6px] ${
                                    item?.print_count > 0 && "leading-[8.7px]"
                                  }`}
                                >
                                  Printed - {item?.print_count}
                                </p>
                              )}
                            </div>
                            <div className="flex justify-between">
                              <div className="flex items-center space-x-4 ">
                                <Tooltip title="Edit Bag">
                                  {" "}
                                  <p
                                    onClick={() =>
                                      handleModalOpen(
                                        item.type,
                                        item.id,
                                        item.batch_number
                                      )
                                    }
                                    className="#919DA9"
                                  >
                                    <EditOutlined
                                      size={16}
                                      style={{ color: "#919DA9" }}
                                      color="#919DA9"
                                    />
                                  </p>
                                </Tooltip>
                                <Popconfirm
                                  okButtonProps={
                                    {
                                      // loading: deleteUserLoading,
                                    }
                                  }
                                  placement="topLeft"
                                  title="Print Bag"
                                  okText="Print with Count"
                                  cancelText="View"
                                  onConfirm={() =>
                                    downdSinglePdf(
                                      SingleBatch?.id,
                                      item.batch_number
                                    )
                                  }
                                  onCancel={() => {
                                    dispatch(
                                      actionDownloadSingleBag({
                                        id: SingleBatch?.id,
                                        batch_number: item.batch_number,
                                      })
                                    );
                                  }}
                                >
                                  <Tooltip placement="bottom" title="Print Bag">
                                    {" "}
                                    <div className="">
                                      {" "}
                                      {downloadSingleBagLoader[
                                        item.batch_number
                                      ] ? (
                                        <LoadingOutlined
                                          size={"16"}
                                          color="#00205E"
                                        />
                                      ) : (
                                        <p>
                                          <img src={print} alt="print" />
                                        </p>
                                      )}
                                    </div>
                                  </Tooltip>
                                </Popconfirm>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card>
                    </List.Item>
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="pt-10 flex items-center justify-center flex-col"
          />
        )}
      </div>
      {/* Sidebar  Section Start Here */}

      <Modal
        open={modalVisible}
        className="edit-bag-modal"
        title={<p className="text-primary ml-5 text-[18px]">Update Bag</p>}
        onCancel={handleModalClose}
        footer={null}
      >
        {singleBagLoader ? (
          <Spin className="min-h-[400px] flex items-center justify-center" />
        ) : (
          renderTypeForms()
        )}
      </Modal>
    </div>
  );
};

export default Lot;

import { Button, Col, Form, Input, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { actionAddBags, actionUpdateBag } from '../../../store/services/lotService';

const EditGradeFiltermediaSand = ({ id, setModalVisible }) => {
    const [selectedValue, setSelectedValue] = useState("");
    const [customValidationError, setCustomValidationError] = useState("");
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const grade = useSelector(state => state.lot)
    const { user } = useSelector(state => state.auth)

    const { singleBag, singleBagLoader } = grade;

    useEffect(() => {
        form.setFieldsValue({
            effective_size: singleBag.effective_size ? singleBag.effective_size : "",
            uc: singleBag.uc ? singleBag.uc : "",
            specific_gratuity: singleBag.specific_gratuity ? singleBag.specific_gratuity : "",
            loss_on_ignition: singleBag.loss_on_ignition ? singleBag.loss_on_ignition : "",
            impurities: singleBag.impurities ? singleBag.impurities : "",
            acid_solubility: singleBag.acid_solubility ? singleBag.acid_solubility : "",
        })
    }, [singleBag, form])

    const onFinish = (values) => {
        const req = {
            ...values,
            type: "Is_17955_X",
            effective_size: values.effective_size ? parseFloat(values.effective_size).toFixed(2) : "",
            uc: values.uc ? parseFloat(values.uc).toFixed(1) : "",
            specific_gratuity: values.specific_gratuity ? values.specific_gratuity : "",
            loss_on_ignition: values.loss_on_ignition ? parseFloat(values.loss_on_ignition).toFixed(1) : "",
            impurities: values.impurities ? values.impurities : "",
            acid_solubility: values.acid_solubility ? values.acid_solubility : ""
        }
        dispatch(actionUpdateBag({ id, val: req, setModalVisible, form }))
    };

    const onFinishFailed = (err) => {
        if (selectedValue.length <= 0) {
            setCustomValidationError('Please select a Machine Type.');
            console.log("Empty");
        } else {
            setCustomValidationError("");
        }
        console.log({ err });
    }
    return (
        <>
            <div className='rounded-[15px] px-4 py-1 bg-white  my-2 '>
                <Form initialValues={{
                    pass_through: 100,
                    retain_on: "0.0"
                }}
                    className="lot-form"
                    form={form}
                    layout="vertical"
                    size="large"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>
                    <div className='text-[20px] text-[#000] mt-3'>
                        IS 17955 : 2023 ( Filtermedia Sand )
                    </div>

                    <Row gutter={[16, 0]} className="graed  mt-3">
                        <Col span={12}>
                            <Form.Item
                                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Effective Size</p><p className='text-[12px] text-[#717171] text-right'>( 0.45 - 0.70 )</p></div>}
                                name="effective_size"
                                normalize={(value) =>
                                    value.replace(/[^0-9.]/g, "").trim()
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Effective Size!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && !(value >= 0.45 && value <= 0.70)) {
                                                return Promise.reject('Please enter a valid value from 0.45 to 0.70, inclusive!');
                                            }
                                            return Promise.resolve();
                                        },
                                    })
                                ]}
                            >
                                <Input className='' placeholder='Enter Effective Size' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Uniformity Coefficient (uc)</p><p className='text-[12px] text-[#717171] text-right'>( 1.3 - 1.7 )</p></div>}
                                name="uc"
                                normalize={(value) =>
                                    value.replace(/[^0-9.]/g, "").trim()
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Uniformity Coefficient!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && !(value >= 1.3 && value <= 1.7)) {
                                                return Promise.reject('Please enter a valid value from 1.3 to 1.7, inclusive!');
                                            }

                                            return Promise.resolve();
                                        },
                                    })
                                ]}
                            >
                                <Input className='' placeholder='Enter Uniformity Coefficient' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Specific Gravity"
                                name="specific_gratuity"
                                normalize={(value) =>
                                    value.replace(/[^0-9.]/g, "").trim()
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Specific Gravity!',
                                    },
                                    // ({ getFieldValue }) => ({
                                    //     validator(_, value) {
                                    //         if (value && !(value >= 2.50)) {
                                    //             return Promise.reject('Please enter a value greater than or equal to 2.50!');
                                    //         }

                                    //         return Promise.resolve();
                                    //     },
                                    // })
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && value <= 2.50) {
                                                return Promise.reject('Please enter a value greater than 2.50!');
                                            }
                                            return Promise.resolve();
                                        },
                                    })
                                ]}

                            >
                                <Input placeholder='Enter Specific Gravity' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Loss on Ignition</p><p className='text-[12px] text-[#717171] text-right'>( 0.1% - 0.7% )</p></div>}
                                name="loss_on_ignition"
                                normalize={(value) =>
                                    value.replace(/[^0-9.]/g, "").trim()
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Loss on Ignition!',
                                    },
                                    // ({ getFieldValue }) => ({
                                    //     validator(_, value) {
                                    //         if (value && !(value >= 0.1 && value <= 0.7)) {
                                    //             return Promise.reject('Please enter a valid value from 0.1 to 0.7, inclusive!');
                                    //         }

                                    //         return Promise.resolve();
                                    //     },
                                    // })
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && !(value >= 0.1 && value < 0.7)) {
                                                return Promise.reject('Please enter a valid value from 0.1 to less than 0.7!');
                                            }
                                            return Promise.resolve();
                                        },
                                    })
                                ]}

                            >
                                <Input placeholder='Enter Loss on Ignition' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Impurities (clay, loam, silt etc...)</p><p className='text-[12px] text-[#717171] text-right'>( 0 - 5% )</p></div>}
                                name="impurities"
                                normalize={(value) =>
                                    value.replace(/[^0-9.]/g, "").trim()
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Impurities!',
                                    },
                                    // ({ getFieldValue }) => ({
                                    //     validator(_, value) {
                                    //         if (value && !(value >= 0 && value <= 5)) {
                                    //             return Promise.reject('Please enter a valid value from 0 to 5, inclusive!');
                                    //         }

                                    //         return Promise.resolve();
                                    //     },
                                    // })
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && !(value >= 0 && value < 5)) {
                                                return Promise.reject('Please enter a valid value from 0 to less than 5!');
                                            }
                                            return Promise.resolve();
                                        },
                                    })
                                ]}

                            >
                                <Input placeholder='Enter Impurities' />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<div className='flex w-full items-center justify-between'><p className='text-[12px]'>Acid Solubility</p><p className='text-[12px] text-[#717171] text-right'>( 0 - 5% )</p></div>}
                                name="acid_solubility"
                                normalize={(value) =>
                                    value.replace(/[^0-9.]/g, "").trim()
                                }
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please Enter Acid Solubility!',
                                    },
                                    // ({ getFieldValue }) => ({
                                    //     validator(_, value) {
                                    //         if (value && !(value >= 0 && value <= 5)) {
                                    //             return Promise.reject('Please enter a valid value from 0 to 5, inclusive!');
                                    //         }

                                    //         return Promise.resolve();
                                    //     },
                                    // })
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && !(value >= 0 && value < 5)) {
                                                return Promise.reject('Please enter a valid value from 0 to less than 5!');
                                            }
                                            return Promise.resolve();
                                        },
                                    })
                                ]}

                            >
                                <Input placeholder='Enter Acid Solubility' />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className='footer z-0  flex items-center gap-x-2 bottom-0 w-full   bg-transparent 2xl:h-[85px] xl:h-[65px] lg:h-[55px] sm:h-[50px]  2xl:py-[17px]  xl:py-3 py-3'>
                        <Form.Item style={{ marginBottom: 0 }}>
                            <Button htmlType="submit" className='outline-btn 2xl:text-[16px] xl:text-[14px] text-[12px] ml-2'>Update Bag</Button>
                        </Form.Item>
                    </div>
                </Form></div>
        </>
    )
}

export default EditGradeFiltermediaSand